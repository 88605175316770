import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slide1 from '../assets/slide1.jpg'
import Slide2 from '../assets/slide2.jpg'
import Slide4 from '../assets/slide4.jpg'
import Slide5 from '../assets/slide5.jpg'

import Slide6 from '../assets/slide6.jpg'
import Slide7 from '../assets/slide7.jpg'
import Slide8 from '../assets/slide8.jpg'
import Slide9 from '../assets/slide9.jpg'
import Slide10 from '../assets/slide10.jpg'
import Slide11 from '../assets/slide11.jpg'
import Slide12 from '../assets/slide12.jpg'
import Slide13 from '../assets/slide13.jpg'
// import Slide14 from '../assets/slide14.jpg'
import Slide15 from '../assets/slide15.jpg'
import '../styles/carousel.scss'

const Carousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div className="carousel-slide">
                    <img src={Slide1} alt='slide 1' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide2} alt='slide 2' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide5} alt='slide 5' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide4} alt='slide 4' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide6} alt='slide 6' />
                </div>      
                <div className="carousel-slide">
                    <img src={Slide7} alt='slide 7' />
                </div>   
                <div className="carousel-slide">
                    <img src={Slide8} alt='slide 8' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide9} alt='slide 9' />
                </div>
                <div className="carousel-slide">
                    <img src={Slide10} alt='slide 10' />
                </div>


                <div className="carousel-slide">
                    <img src={Slide11} alt='slide 11' />
                </div>
                
                <div className="carousel-slide">
                    <img src={Slide12} alt='slide 12' />
                </div>
                
                <div className="carousel-slide">
                    <img src={Slide13} alt='slide 13' />
                </div>
                {/* <div className="carousel-slide">
                    <img src={Slide14} alt='slide 14' />
                </div> */}
                
                <div className="carousel-slide">
                    <img src={Slide15} alt='slide 15' />
                </div>
            </Slider>
        </div>
    );
};

export default Carousel;