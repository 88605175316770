import React, { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../styles/contactus.scss';

import SocialMediaIcons from '../components/SocialMedia';//' components/SocialMedia';

const ContactForm = ({ content }) => {
  AOS.init();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add logic here to handle form submission
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contact" data-aos="zoom-out" data-aos-delay="500" data-aos-once="true">
      <div  className="footer-container">
        <h3>{content && content["social-media"] && content["social-media"]["hint"]}</h3>
        {/* Include the SocialMediaIcons component */}
        <SocialMediaIcons />
      </div>
    </div>
  );
};

export default ContactForm;
