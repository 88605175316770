import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll'
import Logo from '../assets/logo.png'
import '../styles/navbar.scss';
import iraqFlag from '../assets//Iraq-flag.png';
import usaFlag from '../assets//USA-flag.png';
import { useLanguage } from '../Providers/LanguagesContext';
const Navbar = () => {
  // State to manage whether the navbar should shrink or not
  const [shrinkNavbar, setShrinkNavbar] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }


  // Styles
const buttonStyle = {
  backgroundColor: 'transparent', // Set background to transparent
  border: 'none', // Remove border
  cursor: 'pointer', // Change cursor to pointer on hover
  padding: '0', // Remove padding
};


  // Effect to add a scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      // Update the state based on the scroll position
    //  if (window.scrollY > 50) {
        setShrinkNavbar(true);
      // } else {
      //   setShrinkNavbar(false);
      // }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const { language, changeLanguage } = useLanguage();



  const handleChangeLanguage = () => {
    // Change language to 'fr' (French), for example
    changeLanguage('EN');
    changeLanguage(language === "EN" ? "AR" : "EN");
  };
  return (
    <nav className={`navbar ${shrinkNavbar ? 'shrink' : ''}`}>
      <div className="logo">
        <NavLink to="/">
          <img src={Logo} alt="Logo" />
        </NavLink>
      </div>
      <div className={`links ${isOpen ? 'active' : ''}`}>
        <NavLink className='navlink' to='/' >
          {/* Home */}
        </NavLink>
        <Link to='services' smooth={true} duration={500} offset={-700}>
          {/* Services */}
          Home
        </Link>
        <Link to='aboutUs' smooth={true} duration={500}>
          About Us
        </Link>
        <Link to='contactUs' smooth={true} duration={500}>
          Contact Us
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
          <div className='bars'>
            <div className={`bar ${isOpen ? 'animate' : ''}`}></div>
            <div className={`bar ${isOpen ? 'animate' : ''}`}></div>
            <div className={`bar ${isOpen ? 'animate' : ''}`}></div>
          </div>
      </div>
      <div className={`overlay ${isOpen ? 'act' : ''}`}></div>
      <button  style={buttonStyle}  onClick={handleChangeLanguage} >
        
      <img src={language ==="AR"?iraqFlag:usaFlag} alt={language === 'EN' ? 'EN' : 'AR'} style={{ width: '50px', height: 'auto', marginRight: '5px' }} />
      
        {/* {language === "EN" ? "EN" : "AR"}
         */}
        
        
        </button>
    
    </nav>
  );
};

export default Navbar;
