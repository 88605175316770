import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/services.scss'
import Paper_Cub from '../assets/paper-cup.png';
import Grocery_Bag from '../assets/grocery-bag.png';
import roll from '../assets/roll.png';
import paper_fan from '../assets/paper-fan.png';
import paper_bags from '../assets/paper_bags.png';

const Services =({ content, language }) => {
  AOS.init();

  // const [language, setLanguage] = useState("EN");
  // const [content, setContent] = useState({});

  // useEffect(() => {
  //   if (language === "AR") {
  //     setContent(data);
  //   } else {
  //     setContent(dataEN);
  //   }
  // }, [language]);

  return (
    <div className='services-section'>
      <div className='service-subhead' data-aos='fade-up' data-aos-delay='200' data-aos-once="true">
        {content.test && content.test.test1}
        {content.services && content.services.title}
        {content.services && content.services.question}
      </div>
      <div className='service-head' data-aos='fade-up' data-aos-delay='300' data-aos-once="true">
        {content.services && content.services.title}
      </div>
      <div className='services-container' data-aos='fade-up' data-aos-delay='400' data-aos-once="true">
        <div className='service'>
          <div className='service-icon'><img src={roll} alt='Paper Cubs' /></div>
          <div className='service-title'>{content.services && content.services.service1.title}</div>
          <div className='service-description'>{content.services && content.services.service1.text}</div>
        </div>
        <div className='service'>
          <div className='service-icon'><img src={paper_fan} alt='Grocery Bag' /></div>
          <div className='service-title'>{content.services && content.services.service2.title}</div>
          <div className='service-description'>{content.services && content.services.service2.text}</div>
        </div>
        <div className='service'>
          <div className='service-icon'><img src={Paper_Cub} alt='Food Container' /></div>
          <div className='service-title'>{content.services && content.services.service3.title}</div>
          <div className='service-description'>{content.services && content.services.service3.text}</div>
        </div>
        <div className='service'>
          <div className='service-icon'><img src={paper_bags} alt='Electronic Container' /></div>
          <div className='service-title'>{content.services && content.services.service4.title}</div>
          <div className='service-description'>{content.services && content.services.service4.text}</div>
        </div>
      </div>
    </div>
  );
}

export default Services;
