import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../styles/aboutus.scss';
import AboutUs from '../assets/aboutus.jpg';

const AboutUsSection = ({ content, language }) => {
  AOS.init();

  // Check the language and set the direction accordingly
  const isRTL = language === 'AR';

  return (
    <div className='aboutus-section'>
      <div className='aboutus-container'>
        <img src={AboutUs} className='aboutus-img' alt="" data-aos='fade-right' data-aos-delay='200' data-aos-once="true" />
        <div className='aboutus-sec'>
          <div className='aboutus-subhead' data-aos='fade-left' data-aos-delay='200' data-aos-once="true">
            {content && content.about_us && content.about_us.question}
          </div>
          <div className='aboutus-head' data-aos='fade-left' data-aos-delay='300' data-aos-once="true">
            {content && content.about_us && content.about_us.title}
          </div>
          <div className={`aboutus-desc ${isRTL ? 'rtl' : 'ltr'}`} data-aos='fade-left' data-aos-delay='400' data-aos-once="true">
            {content && content.about_us && content.about_us.text_commas}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
