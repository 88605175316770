import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll'
import Carousel from '../components/Carousel'
import Services from '../components/Services'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import '../styles/home.scss'
import dataEN from '../Resources/English/en.json'; // Import English translation file
import dataAR from '../Resources/ar.json'; // Import Arabic translation file

// import { useLanguage } from './Providers/LanguagesContext';
import { useLanguage } from '../Providers/LanguagesContext';
const Home = () => {

  const { language, changeLanguage } = useLanguage();




const [content, setContent] = useState({}); // State to hold content data

  // Load translation data dynamically based on selected language
  const translationData = language === "EN" ? dataEN : dataAR;

  // Set content state with translation data
  useEffect(() => {
    setContent(translationData);
  }, [language, translationData]);

  return (
    <div className='home'>
     
      {/* Carousel component */}
      <Carousel />

      {/* Services component */}
      <Element name='services'>
        <Services content={content} />
      </Element>

      {/* AboutUs component */}
      <Element name='aboutUs'>
        <AboutUs content={content} />
      </Element>

      {/* ContactUs component */}
      <Element name='contactUs'>
        <ContactUs content={content} />
      </Element>
      <div>
       </div>  
      
    </div>
  ); 
}

export default Home